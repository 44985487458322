import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';

import { PERSONALIZATION_BASE_API, POLICY_BASE_API } from '@pumpkincare/config';
import { buildAuthHeaders } from '@pumpkincare/shared';

import { transformPolicyOptions, transformRecommendations } from './plan-utils';

export function getPetPolicy(quoteId, quotePetId) {
  const url = `${POLICY_BASE_API}/quotes-v3/${quoteId}/pets/${quotePetId}/policies`;

  return axios.get(url).then(response => response.data.body);
}

export function getPlan(petBreedSpecies, petAgeInMonths, userId, vetId) {
  const url = `${POLICY_BASE_API}/plans/${petBreedSpecies}/${petAgeInMonths}${
    userId ? `/${userId}` : ''
  }${vetId ? `?vet_id=${vetId}` : ''}`;

  function getPlan(headers) {
    return axios.get(url, headers).then(response => response.data.body);
  }

  return (
    userId
      ? fetchAuthSession().then(authSession => {
          return getPlan(buildAuthHeaders(authSession));
        })
      : getPlan()
  ).then(
    response => response[0] // should the endpoint return be just an object?
  );
}

export function getPolicyOptions(quoteId, petId, isLoggedIn) {
  const url = `${POLICY_BASE_API}/quotes-v3/${quoteId}/pets/${petId}/policy-options`;

  function getResponse(headers) {
    return axios.get(url, headers).then(response => response.data);
  }

  return (
    isLoggedIn
      ? fetchAuthSession().then(authSession => {
          return getResponse(buildAuthHeaders(authSession));
        })
      : getResponse()
  ).then(transformPolicyOptions);
}

export function getRecommendations(identityId, page, { species, prevent }) {
  const url = `${PERSONALIZATION_BASE_API}/identities/${identityId}/recommendations/${page}/recommendation-options`;

  return axios
    .get(url, { params: { species, prevent } })
    .then(response => response.data)
    .then(transformRecommendations);
}
