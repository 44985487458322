export default function transformFinalize(
  stripeToken,
  quotes,
  isChargedAnnually,
  termsVersion = null,
  isPaperless = null
) {
  const { billingAddress, isShippingSameAsBilling, paymentInfo, shippingAddress } =
    quotes;
  const shippingInformation = {
    first_name: shippingAddress.firstName.value,
    last_name: shippingAddress.lastName.value,
    street_1: shippingAddress.address1.value,
    street_2: shippingAddress.address2.value,
    country: 'US',
    state: shippingAddress.state.value,
    city: shippingAddress.city.value,
    zipcode: shippingAddress.zipcode.value,
  };

  return {
    billed_annually: isChargedAnnually,

    billing_information: {
      ...(isShippingSameAsBilling
        ? shippingInformation
        : {
            first_name: shippingAddress.firstName.value,
            last_name: shippingAddress.lastName.value,
            street_1: billingAddress.address1.value,
            street_2: billingAddress.address2.value,
            country: billingAddress.country.value,
            state: billingAddress.state.value,
            city: billingAddress.city.value,
            zipcode: billingAddress.zipcode.value,
          }),

      name_on_card: paymentInfo.nameShownOnCard.value,
    },

    is_paperless: isPaperless,
    phone: shippingAddress.phone.value,

    shipping_information: shippingInformation,

    stripeToken,
    terms_version: termsVersion,
  };
}
