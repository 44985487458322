import { getNumbersFromString } from './currency-utils';

export function getFullPercentage(value) {
  let calc;
  if (value) {
    if (!value.startsWith('.')) {
      calc = Math.abs((getNumbersFromString(value) / 100) * 100 - 100);
    } else {
      calc = Math.abs(value * 100 - 100);
    }
    return `${calc}%`;
  }
  return null;
}
